// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Set the background image */
  background-size: cover; /* Cover the entire background */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  height: 100vh; /* Full viewport height */
  display: flex; /* Use flex to center content */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,yDAA+C,EAAE,6BAA6B;EAC9E,sBAAsB,EAAE,gCAAgC;EACxD,2BAA2B,EAAE,gCAAgC;EAC7D,4BAA4B;EAC5B,aAAa,EAAE,yBAAyB;EACxC,aAAa,EAAE,+BAA+B;EAC9C,sBAAsB,EAAE,2BAA2B;EACnD,mBAAmB,EAAE,8BAA8B;EACnD;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb","sourcesContent":["body {\n  margin: 0;\n  background-image: url('/public/background.jpg'); /* Set the background image */\n  background-size: cover; /* Cover the entire background */\n  background-position: center; /* Center the background image */\n  background-repeat: no-repeat;\n  height: 100vh; /* Full viewport height */\n  display: flex; /* Use flex to center content */\n  flex-direction: column; /* Stack items vertically */\n  align-items: center; /* Center items horizontally */\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
