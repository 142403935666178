import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
// import Index from './pages/index';
// import Details from './pages/details';
// import Trending from './pages/trending';
import './App.css';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/indexer" element={<Index />} />
        <Route path="/details/:tokenId" element={<Details />} />
        <Route path="/trending" element={<Trending />} /> */}
      </Routes>
    </Router>
  );
};

export default App;