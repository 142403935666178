import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './home.css';

function Home() {
  return (
    <div className="home">
      <Link to="/" className="home-image-link">
        <img src="/DCA.jpg" alt="DCA" />
      </Link>

      <div className="quote">
        Department of Crypto Affairs!
      </div>

      <div className="link-container">
        <a href="https://x.com/dca420index" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <img src="./x.png" alt="Twitter Logo" style={{ width: '50px', height: '50px' }} />
        </a>
        <a href="https://t.me/DCA_Official" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <FaTelegramPlane style={{ width: '50px', height: '50px' }} />
        </a>
        <a href="https://dexscreener.com/solana/DcAJAuJKKgqNNjnUKMPK9g1ShA2gNVvwaToB5Ygv7btc" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <img src="./dexscreener.png" alt="Dexscreener Logo" style={{ width: '50px', height: '50px' }} />
        </a>
      </div>

      <br />
      <span className="contract-address-label">Contract Address:</span>
      <a href="https://dexscreener.com/solana/DcAJAuJKKgqNNjnUKMPK9g1ShA2gNVvwaToB5Ygv7btc" className="address-link" target="_blank" rel="noopener noreferrer">
        DcAJAuJKKgqNNjnUKMPK9g1ShA2gNVvwaToB5Ygv7btc
      </a>
    </div>
  );
}

export default Home;
