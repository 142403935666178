import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './home.css';

function Home() {
  return (
    <div className="home">
      <Link to="/" className="home-image-link">
        <img src="/dca.png" alt="DCA" />
      </Link>

      <div className="quote">
        Degenerate Crypto Animals: Meme Coins <span className="highlighted-word">Indexed</span> for the Masses!
      </div>

      <div className="link-container">
        <a href="https://x.com/dca420index" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <img src="./x.png" alt="Twitter Logo" style={{ width: '50px', height: '50px' }} />
        </a>
        <a href="https://t.me/DCA420Index" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <FaTelegramPlane style={{ width: '50px', height: '50px' }} />
        </a>
        <a href="https://www.coingecko.com/en/coins/dca420-meme-index" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <img src="./gecko.png" alt="Coingecko Logo" style={{ width: '50px', height: '50px' }} />
        </a>
        <a href="https://coinmarketcap.com/dexscan/solana/M3icmhYC5h9mjuezCEnRXaf3Rs9cn33kJk71otZYmck/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <img src="./cmc.png" alt="CoinMarketCap Logo" style={{ width: '50px', height: '50px' }} />
        </a>
        <a href="https://dexscreener.com/solana/m3icmhyc5h9mjuezcenrxaf3rs9cn33kjk71otzymck" target="_blank" rel="noopener noreferrer" style={{ color: 'white', fontSize: '2rem' }}>
          <img src="./dexscreener.png" alt="Dexscreener Logo" style={{ width: '50px', height: '50px' }} />
        </a>
      </div>

      <br />
      <span className="contract-address-label">Contract Address:</span>
      <a href="https://dexscreener.com/solana/m3icmhyc5h9mjuezcenrxaf3rs9cn33kjk71otzymck" className="address-link" target="_blank" rel="noopener noreferrer">
        GbM86ZU8UhcSiwkB7uLqWaPnGnQXNKExJebzDBspump
      </a>
    </div>
  );
}

export default Home;